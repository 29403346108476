import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/blogLayout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import styles from "./blog-list.module.css"
import BackgroundImage from "gatsby-background-image"

const BlogList = ({ data, pageContext }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  const { numPages, currentPage, category } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  return (
    <Layout location={pageContext.location} title={siteTitle}>
      <SEO
        title={"Referment Blog"}
        description={
          "Read the latest news and insights from Referment, the FinTech recruitment experts"
        }
      />

      <BackgroundImage
        backgroundColor={`#040e18`}
        // className="background-img-custom "
        style={{ height: "60vh" }}
        className={"black-layover"}
        fluid={data.file?.childImageSharp?.fluid}
      >
        <div
          style={{ backgroundColor: "rgba(0,0,0,0.6)" }}
          className={"flex flex-col justify-center items-center h-full"}
        >
          <h1
            className={"md:text-5xl text-3xl font-semibold text-light"}
            itemProp="headline"
          >
            The Referment Blog
          </h1>
          <p className={"text-light mt-2"}>Recruitment rewarded</p>
        </div>
      </BackgroundImage>

      <div
        className={"container flex flex-col justify-center md: mx-auto pt-12"}
      >
        <div
          className={
            "flex items-end overflow-x-scroll md:overflow-x-auto md:justify-end pb-3"
          }
        >
          <Link
            className={
              category === "referment-guides" ? styles.activePill : styles.pill
            }
            to={
              category === "referment-guides"
                ? `/blog`
                : `/blog/referment-guides`
            }
          >
            Referment guides
          </Link>
          <Link
            className={
              category === "news-and-product" ? styles.activePill : styles.pill
            }
            to={
              category === "news-and-product"
                ? `/blog`
                : `/blog/news-and-product`
            }
          >
            News and product
          </Link>
          <Link
            className={
              category === "success-stories" ? styles.activePill : styles.pill
            }
            to={
              category === "success-stories" ? `/blog` : `/blog/success-stories`
            }
          >
            Success stories
          </Link>
        </div>
        {/*List of posts*/}

        <div className={"grid lg:grid-cols-2"}>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <Link
                key={node.fields.slug}
                to={`/blog/post${node.fields.slug}`}
                className={
                  "bg-light flex lg:flex-row flex-col rounded row-span-1 m-5"
                }
              >
                <div key={node.fields.slug} className="blog-list">
                  <Img
                    className={styles.blogImg}
                    fluid={node.frontmatter.coverImage.childImageSharp?.fluid}
                  />
                </div>

                <div className={"m-5"}>
                  <div className={"text-primary text-lg font-semibold"}>
                    <h2>{title}</h2>
                  </div>
                  <div className={"text-primary text-sm mt-2  "}>
                    <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
        <div>
          <ul
            className={"mt-5"}
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              listStyle: "none",
              padding: 0,
            }}
          >
            {!isFirst && (
              <Link
                className={
                  "bg-secondary w-32 text-center text-primary px-4 py-3 rounded-sm"
                }
                to={`/blog/${category ? `${category}/` : ""}${prevPage}`}
                rel="prev"
              >
                Previous
              </Link>
            )}
            {/* {Array.from({ length: numPages }, (_, i) => (
              <li
                key={`pagination-number${i + 1}`}
                style={{
                  margin: 0,
                }}
              >
                <Link
                  to={`/blog/${i === 0 ? "" : i + 1}`}
                  style={
                    i + 1 === currentPage ?
                    {
                    marginTop: "0.1rem",
                    marginBottom: "0.1rem",
                    padding: "0.5rem",
                    textDecoration: "none",
                    color:
                      i + 1 === currentPage ? "#000000" : "var(--headerColor)",
                    background:
                      i + 1 === currentPage ? "var(--headerColor)" : "",
                  }
                :
                {

                }
                }
                >
                  {i + 1}
                </Link>
              </li>
            ))} */}
            {!isLast && (
              <Link
                className={
                  "bg-secondary w-32 text-center m-2 text-primary px-4 py-3 rounded-sm"
                }
                to={`/blog/${category ? `${category}/` : ""}${nextPage}`}
                rel="next"
              >
                {"Next"}
              </Link>
            )}
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default BlogList

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!, $categoryRegex: String) {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "blog-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { frontmatter: { category: { regex: $categoryRegex } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            coverImage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
